import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button,
  ButtonToolbar,
} from "reactstrap";
import Switch from "react-switch";
import HorarioDias from "./horarioDias";
import { semana } from "../../../../helpers/Fichas/constants";

import {
  fetchAsync,
  urlUsers,
  urlCourseLevels,
} from "../../../../helpers/Globals/globals";
import DeleteButtonConfirm from "../../../../helpers/DeleteButtonConfirm/DeleteButtonConfirm";
import SelectClassroomLocation from "helpers/classromLocation/index";
import UsersIcon from "mdi-react/UsersIcon";
import TeacherIcon from "mdi-react/TeacherIcon";
import BuildingIcon from "mdi-react/BuildingIcon";
import { DatePicker, Select, Checkbox } from "antd";
import moment from "moment";
import Can from "../../../../helpers/Secure/Can";
import { locale } from "../../../../helpers/Globals/funciones";

import "antd/lib/date-picker/style/index.css";

const { Option } = Select;
const dateFormat = "DD/MM/YYYY";
moment.locale("es");

const StepFormulario = ({
  isEditPlatica,
  newGroupData,
  setNewGroupData,
  deleteTalk,
  talkFullInfo,
  onClosSidebar,
}) => {
  const [modalSalon, setModalSalon] = useState(false);
  const [modalHorarioDias, setModalHorarioDias] = useState(false);
  const [empleados, setEmpleados] = useState([]);
  const [niveles, setNiveles] = useState([]);

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatoHora = (hora) => {
    let uno = hora.slice(0, 2);
    let dos = hora.slice(2, 4);
    return uno + ":" + dos;
  };

  //agrega el array de schedules
  const getHorarioDias = (array) => {
    if (newGroupData.schedules.length <= 0) {
      var agregar = newGroupData.schedules.concat(array);
      setNewGroupData({
        ...newGroupData,
        schedules: agregar,
      });
    } else {
      newGroupData.schedules.forEach((valor, index) => {
        array.forEach((dato, index) => {
          let agregar;
          if (valor.day === dato.day) {
            let inicioE = formatoHora(valor.startHour);
            let finalE = formatoHora(valor.endHour);
            //valores de hora del array
            let inicioA = formatoHora(dato.startHour);
            let finalA = formatoHora(dato.endHour);
            if (inicioA < inicioE && finalA < inicioE) {
              agregar = newGroupData.schedules.concat(array);
            } else if (inicioA > finalE && finalA > finalE) {
              agregar = newGroupData.schedules.concat(array);
            } else {
              alert("Horario se translapa con uno que ya agregaste");
              array = [];
              agregar = newGroupData.schedules.concat(array);
            }
          } else {
            agregar = newGroupData.schedules.concat(array);
          }
          setNewGroupData({
            ...newGroupData,
            schedules: agregar,
          });
        });
      });
    }
  };

  //handler del switch button
  const handleChange = (checked) => {
    setNewGroupData({
      ...newGroupData,
      status: checked,
    });
  };

  //guarda el salón y sede seleccionados
  const saveLocationClassrom = (data) => {
    setNewGroupData({
      ...newGroupData,
      selectedSede: data[0],
      selectedSalon: data[1],
      quota: data[1].capacity,
    });
  };

  const onChangeDateStart = (date) => {
    let localDateHour = moment.utc(date).local().format();
    setNewGroupData({
      ...newGroupData,
      startDate: localDateHour,
      endDate: localDateHour,
    });
  };
  //genera la lista de horarios agregados
  const generaLista = (schedules) => {
    return schedules.map((data, index) => {
      return (
        <div key={index}>
          <Row id={index}>
            <Col xs={12} sm={3}>
              <span className="mr-2">{semana[data.day]}</span>
            </Col>
            <Col xs={12} sm={7}>
              <span className="mr-2">
                <strong>Inicia:</strong> {formatoHora(data.startHour)}
              </span>
              <span className="mr-2">
                <strong>Termina:</strong> {formatoHora(data.endHour)}
              </span>
            </Col>
            <Col xs={12} sm={2}>
              <div onClick={() => clickDeleteSchedule(index)}>
                <i className="material-icons deleteIcon">delete</i>
              </div>
            </Col>
          </Row>
          <hr></hr>
        </div>
      );
    });
  };

  const clickDeleteSchedule = (indice) => {
    let test = [...newGroupData.schedules];
    test.splice(indice, 1);
    setNewGroupData({
      ...newGroupData,
      schedules: test,
    });
  };
  //muestra la lista de profesores en elselect
  const showOptions = () => {
    return empleados.map((value) => {
      return (
        <Option key={value._id} value={value._id}>
          {value.name} {value.lastName} {value.secondLastName}
        </Option>
      );
    });
  };
  const showOptionsLevels = () => {
    return niveles.map((value) => {
      return <Option key={value._id}> {value.name}</Option>;
    });
  };

  /*
   ***********************************
   ***********************************
   ************ NETWORK OPS **********
   ***********************************
   ***********************************
   */
  const getUsers = () => {
    return fetchAsync(urlUsers, "", "GET", "")
      .then((result) => {
        if (result.success === true) {
          let profesores = result.users.filter((dato) => dato.isProfessor);
          setEmpleados(profesores);
          getLevels();
        }
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  const getLevels = () => {
    return fetchAsync(urlCourseLevels, "", "GET", "")
      .then((result) => {
        if (result.success === true) {
          setNiveles(result.courseLevels);
        }
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  const onChange = (e) => {
    setNewGroupData({
      ...newGroupData,
      isFeria: e.target.checked,
    });
  };
  /*
   **********************************
   ***********************************
   ************* RENDER **************
   ***********************************
   **********************************
   */
  return (
    <Container>
      <Row>
        <Col sm={12} md={12}>
          <Card>
            <CardBody>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Nombre de la platica (interno)
                </span>
                <div className="form__form-group-field">
                  <input
                    className="form-control"
                    id="inpuName"
                    component="input"
                    type="text"
                    onChange={(event) => {
                      setNewGroupData({
                        ...newGroupData,
                        name: event.target.value,
                      });
                    }}
                    value={newGroupData.name}
                  />
                </div>
              </div>

              <Select
                showSearch
                defaultValue={newGroupData.courseLevel}
                style={{ width: 220 }}
                placeholder="Asignar nivel"
                onChange={(value) => {
                  setNewGroupData({
                    ...newGroupData,
                    courseLevel: value,
                  });
                }}
              >
                {showOptionsLevels()}
              </Select>

              <section className="mt-2">
                {newGroupData.schedules.length > 0 && (
                  <h4>Horario de la plática</h4>
                )}
                {generaLista(newGroupData.schedules)}
              </section>

              <Row className="mt-4 mb-2">
                <Col sm={12} md={7}>
                  <p>Fecha</p>
                  <DatePicker
                    defaultValue={moment(newGroupData.startDate, dateFormat)}
                    onChange={onChangeDateStart}
                    format={dateFormat}
                    locale={locale}
                  />
                </Col>
                <Col sm={12} md={5} className="text-center">
                  <Button
                    className="mt-2"
                    color="success"
                    size="sm"
                    onClick={() => setModalHorarioDias(true)}
                  >
                    <p>Agregar horario</p>
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col sm={12} md={8}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Cupo del grupo
                    </span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <UsersIcon />
                      </div>
                      <input
                        id="capacity"
                        type="number"
                        className="form-control"
                        style={{ width: "100px" }}
                        value={newGroupData.quota}
                        onChange={(e) => {
                          setNewGroupData({
                            ...newGroupData,
                            quota: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={4}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Estatus</span>
                    <div className="form__form-group-field">
                      <div className="form__form-group">
                        <div className="form__form-group-field">
                          <label>
                            <Switch
                              onChange={handleChange}
                              checked={newGroupData.status}
                              onColor="#2ecc71"
                              onHandleColor="#2ecc71"
                              handleDiameter={30}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              height={20}
                              width={48}
                              className="react-switch"
                              id="material-switch"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={7}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Sede</span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <BuildingIcon />
                      </div>
                      <input
                        disabled
                        id="sede"
                        type="text"
                        className="form-control"
                        value={
                          newGroupData.selectedSede
                            ? newGroupData?.selectedSede?.name
                            : ""
                        }
                      />
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={5}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Salón</span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <TeacherIcon />
                      </div>
                      <input
                        disabled
                        id="salon"
                        type="text"
                        className="form-control"
                        value={
                          newGroupData.selectedSede
                            ? newGroupData?.selectedSalon?.name
                            : ""
                        }
                      />
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={12} className="mt-1 mb-1">
                  <ButtonToolbar>
                    <Button
                      disabled={!newGroupData.schedules.length > 0}
                      color="success"
                      size="sm"
                      onClick={() => setModalSalon(true)}
                    >
                      <p>Agregar salón</p>
                    </Button>
                  </ButtonToolbar>
                </Col>
              </Row>
              <Row>
                <Col sm={12} className="my-2">
                  <Checkbox checked={newGroupData?.isFeria} onChange={onChange}>Es Casa del Terror</Checkbox>
                </Col>
                <Col sm={12}>
                  <Select
                    showSearch
                    defaultValue={newGroupData.selectedProfessor}
                    placeholder="Asignar un ponente de platica"
                    onChange={(value) => {
                      setNewGroupData({
                        ...newGroupData,
                        selectedProfessor: value,
                      });
                    }}
                  >
                    {showOptions()}
                  </Select>
                </Col>
              </Row>
              {isEditPlatica && (
                <Can do="delete" on="/talks/">
                  <DeleteButtonConfirm
                    title="¿Deseas eliminar la platica?"
                    deleteFunction={deleteTalk}
                    id={talkFullInfo?._id}
                    onCloseOptionalFunc={onClosSidebar}
                  />
                </Can>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      {modalHorarioDias && (
        <HorarioDias
          valueModal={modalHorarioDias}
          close={() => setModalHorarioDias(false)}
          getHorarioDias={getHorarioDias}
        />
      )}
      {modalSalon && (
        <SelectClassroomLocation
          value={modalSalon}
          close={() => setModalSalon(false)}
          saveInfo={saveLocationClassrom}
          filterData={newGroupData}
        />
      )}
    </Container>
  );
};
export default StepFormulario;
